import {UserAccount} from './../../../../../data/account.data';

'use strict';

import {IRootScopeService, IScope} from "angular";
import {EmergencyResponse} from "../../../../../data/emergency.data";
import RestService from "../../../../../services/rest.service";
import PrivilegeService from '../../../../../services/privilege.service';
import {RolePrivilege} from '../../../../../data/privileges.enum';
import {AlarmData} from "../../../../../data/alarm.data";


require('./mission.row.entry.component.scss');

export default class AlarmRowEntryComponent {

  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;


  constructor() {
    this.restrict = 'A';
    this.template = require('./mission.row.entry.component.html');
    this.scope = {
      emergency: '='
    };
    this.controller = AlarmRowEntryComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  };
};

class AlarmRowEntryComponentController {
  public emergency: EmergencyResponse;
  private listeners = [];
  private newAlarmCount = 0;
  public MAX_VEHICLES = 2;
  public $rootScope: angular.IRootScopeService;
  public $uibModal: any;
  public $log: angular.ILogService;
  public restService: RestService;
  public account: UserAccount;
  public privilegeService: PrivilegeService;
  public isAdmin: boolean = false;

  constructor($scope: IScope, $rootScope: IRootScopeService, $log: angular.ILogService, dataService, restService: RestService, $uibModal, privilegeService: PrivilegeService) {

    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.restService = restService;
    this.privilegeService = privilegeService;

    if (dataService.hasAccount()) {
      this.account = dataService.getAccount();
      this.isAdmin = this.account.admin;
    }

    $scope.$watch('ctrl.emergency', (oldValue, emergencyResponse: EmergencyResponse) => {
      if (emergencyResponse.externalId) {
        this.listeners.push($rootScope.$on(`new.alarm.${emergencyResponse.externalId}`, (newAlarm, alarmData) => {
          if (emergencyResponse) {
            if (emergencyResponse.externalId === alarmData.externalId) {
              if ((emergencyResponse.parentUserId !== alarmData.userId) && emergencyResponse.parentUserId !== this.account.id) {
                // don't show dot for missions if new alarm (update) is not part of organisations mission in table row entry
                // but don't stop if entry is from admin
                // stop processing event
                return;
              }
            }
          }
          this.newAlarmCount++;
          $scope.$applyAsync();
        }));
      }
    });


    $scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  openMission() {
    if (!this.privilegeService.has(RolePrivilege.Home_Emergency_Details)) {
      return;
    }
    this.$uibModal.open({
      template: require('../../../../modals/alarms/mission.modal/mission.modal.html'),
      controller: 'MissionModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'hu',
      resolve: {
        externalId: () => this.emergency.externalId,
        parentUserId: () => this.emergency.parentUserId,
        allAlarmsDeletedFunction: () => {
          return () => {
            // nothing to do here
          }
        }
      }
    });
  }

  deleteMission() {
    if (!this.privilegeService.has(RolePrivilege.Home_Emergency_List_Delete)) {
      return;
    }
    if (this.emergency.hasMissionReport) {
      // don't allow deleting missions if it has reports
      return;
    }
    this.$uibModal.open({
      template: require('../../../../modals/misc/confirm.delete.modal/confirm.delete.modal.html'),
      controller: 'ConfirmDeleteModalController',
      controllerAs: 'ctrl',
      size: 'md',
      resolve: {
        okFunction: () => {
          return () => {
            this.deleteMissionInner();
          }
        },
        additionalText: () => {
          if (this.account.admin) {
            return 'MISSIONS.ALL_ALARMS_ARE_BEING_DELETED_ADMIN';
          }
          return 'MISSIONS.ALL_ALARMS_ARE_BEING_DELETED';
        }
      }
    });
  }

  private deleteMissionInner() {
    this.$log.info('Deleting mission...');

    this.restService.deleteEmergency(this.emergency).then(data => {
      this.$log.debug(data);
      // Submit event
      this.$rootScope.$emit('mission.deleted', this.emergency);
    }, (err) => {
      this.$log.error(err);
    });
  }


  getVehiclesFormatted(): string {
    if (this.emergency.vehicles.length == 0) {
      return '';
    }
    if (this.emergency.vehicles.length == 1) {
      return this.emergency.vehicles[0].name;
    }
    return `${this.emergency.vehicles[0].name}, ${this.emergency.vehicles[1].name}`;
  }

  getAllVehiclesFormatted(): string {
    let str = '';
    this.emergency.vehicles.forEach(vehicle => {
      if (str !== '') {
        str += ', ';
      }
      str += vehicle.name
    });
    return str;
  }

  parseDate(timestamp: string): Date {
    return new Date(timestamp);
  }
}
